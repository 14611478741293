export default {

  setCssVar(name, value) {
    const root = document.documentElement;
    root.style.setProperty(`--${name}`, value);
  },

  getCssVar(name) {
    const root = document.documentElement;
    return getComputedStyle(root).getPropertyValue(`--${name}`);
  }

};

// angle creator

const ANGLE_DEG = 56,
  ANGLE_RAD = ANGLE_DEG * Math.PI / 180,
  ANGLE_TAN = Math.tan(ANGLE_RAD);

const diagonalClipResizeObserver = new ResizeObserver(entries => {
  console.log(entries);
  for (const entry of entries) {
    const elem = entry.target,
      height = entry.borderBoxSize[0].blockSize,
      style = getComputedStyle(elem),
      clipTop = style.getPropertyValue('--diagonal-top').trim(),
      clipBottom = style.getPropertyValue('--diagonal-bottom').trim();

    let topYMagnitude = 0, topXMagnitude = 0, topUnit = 'px',
      botYMagnitude = 0, botXMagnitude = 0, botUnit = 'px';

    // Top diagonal
    if (clipTop) {
      topYMagnitude = parseInt(clipTop);
      topUnit = isNaN(clipTop) ? clipTop.replace(topYMagnitude, '') : 'px';
      if (topUnit == '%') {
        topYMagnitude *= height / 100;
        topUnit = 'px';
      }
      topXMagnitude = topYMagnitude / ANGLE_TAN;
    }

    // Bottom diagonal
    if (clipBottom) {
      botYMagnitude = parseInt(clipBottom);
      botUnit = isNaN(clipBottom) ? clipBottom.replace(botYMagnitude, '') : 'px';
      if (botUnit == '%') {
        botYMagnitude *= height / 100;
        botUnit = 'px';
      }
      botXMagnitude = botYMagnitude / ANGLE_TAN;
    }

    const clipPoints = [
      `${topXMagnitude}${topUnit} 0`,
      '100% 0',
      `100% calc(100% - ${botYMagnitude}${botUnit})`,
      `calc(100% - ${botXMagnitude}${botUnit}) 100%`,
      '0 100%',
      `0 ${topYMagnitude}${topUnit}`
    ];
    elem.style.clipPath = `polygon(${clipPoints.join(', ')})`;
    elem.style.cursor = "default";
    elem.style.pointerEvents = "none";
  }
});

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-diagonal-clip]').forEach(elem => {
    diagonalClipResizeObserver.observe(elem);
  });
});
