export default {

  init() {

    // Show/hide grid on Ctrl+G
    document.addEventListener('keypress', event => {
      if(event.code == 'KeyG' && event.ctrlKey) {
        const gridIsVisible = !document.querySelector('#dev-grid').classList.toggle('hidden');
        localStorage.setItem('gridIsVisible', gridIsVisible);
      }
    });

    // Prevent default for all # links
    document.querySelectorAll('a[href="#"]').forEach(
      a => a.addEventListener('click', event => event.preventDefault())
    );  
  }
  
}

// Persist and apply grid visibility
window.addEventListener('DOMContentLoaded', () => {
  setInterval(() => {
    const gridIsVisible = JSON.parse(localStorage.getItem('gridIsVisible'));
    document.querySelector('#dev-grid').classList.toggle('hidden', !gridIsVisible);
  }, 500);
});