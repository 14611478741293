export default {

  init() {
    window.initMaps = this.processMaps;
  },

  processMaps() {
    document.querySelectorAll('.map-container').forEach(mapContainer => {
      const mapInfo = JSON.parse(mapContainer.dataset.mapinfo);
      const image = '/wp-content/themes/alvic/assets/img/marker.svg';
      const zoomControlslDiv = document.createElement("div");
      let map, markers;
      
      zoomControlslDiv.classList.add('zoom-control');
      zoomControlslDiv.style.display = "flex";

      function ZoomControls(zoomControlslDiv, map) {
        // Set CSS for the control border.
        const zoomout = document.createElement("div");

        zoomout.style.backgroundColor = "#000000";
        zoomout.style.cursor = "pointer";
        zoomout.style.textAlign = "center";
        zoomout.title = "REDUCIR";
        zoomControlslDiv.appendChild(zoomout);

        // Set CSS for the control interior.
        const zoomoutText = document.createElement("div");

        zoomoutText.style.color = "#ffffff";
        zoomoutText.style.fontFamily = "Euclid Circular A,Roboto,Arial,sans-serif";
        zoomoutText.style.fontSize = "12px";
        zoomoutText.style.lineHeight = "16,8px";
        zoomoutText.style.paddingLeft = "32px";
        zoomoutText.style.paddingRight = "12px";
        zoomoutText.style.paddingTop = "18.5px";
        zoomoutText.style.paddingBottom = "18.5px";
        zoomoutText.innerHTML = "REDUCIR";
        zoomout.appendChild(zoomoutText);
        // Setup the click event listeners
        zoomout.addEventListener("click", () => {
          map.setZoom(map.getZoom() - 1);
        });

        // Set CSS for the control border.
        const zoomin = document.createElement("div");

        zoomin.style.backgroundColor = "#000000";
        zoomin.style.cursor = "pointer";
        zoomin.style.textAlign = "center";
        zoomin.title = "AMPLIAR";
        zoomControlslDiv.appendChild(zoomin);

        // Set CSS for the control interior.
        const zoominText = document.createElement("div");

        zoominText.style.color = "#ffffff";
        zoominText.style.fontFamily = "Euclid Circular A,Roboto,Arial,sans-serif";
        zoominText.style.fontSize = "12px";
        zoominText.style.lineHeight = "16,8px";
        zoominText.style.paddingLeft = "12px";
        zoominText.style.paddingRight = "32px";
        zoominText.style.paddingTop = "18.5px";
        zoominText.style.paddingBottom = "18.5px";
        zoominText.innerHTML = "AMPLIAR";
        zoomin.appendChild(zoominText);
        // Setup the click event listeners
        zoomin.addEventListener("click", () => {
          map.setZoom(map.getZoom() + 1);
        });
      }

      switch (mapInfo[0]) {
        case 'complete': 
        case 'single':
          const uluru = { lat: mapInfo[1][0].latitude*1, lng: mapInfo[1][0].longitude*1 };
          map = new google.maps.Map(mapContainer, {
            zoom: 4,
            center: uluru,
            mapId: '9a7c4f083fb64779',
            disableDefaultUI: true,
          });

          markers = [
            new google.maps.Marker({
              position: uluru,
              map: map,
              icon: image,
            })
          ];
          break;

        case 'multiple':
          var bounds = new google.maps.LatLngBounds();
          map = new google.maps.Map(mapContainer, {
            zoom: 3,
            center: { lat: mapInfo[1][0].latitude*1, lng: mapInfo[1][0].longitude*1 },
            mapId: '9a7c4f083fb64779',
            disableDefaultUI: true,
          });

          const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
          const locations = [];
          mapInfo[1].forEach(
            (element, index) => {
              locations.push(
                { lat: element.latitude*1, lng: element.longitude*1 }
              ); 
            }
          );
          
          markers = locations.map((location, i) => {
            const marker = new google.maps.Marker({
              position: location,
              label: labels[i % labels.length],
              icon: image,
            });

            bounds.extend(marker.position);

            google.maps.event.addListener(marker, 'click', function(evt) {
               const card = document.querySelector('div.data');
               //card.querySelector('.type').innerText = location.location_type;
               card.querySelector('.name').innerText = mapInfo[2][i];
               card.querySelector('.desc').innerText = `${mapInfo[1][i].address} \n ${mapInfo[1][i].postal_code} ${mapInfo[1][i].city}, ${mapInfo[1][i].country}` ;
               card.querySelector('.phone').innerText = `Tel. +34 ${mapInfo[1][i].phone}`;
             });
            return marker;
          });

          map.fitBounds(bounds);

          // Add a marker clusterer to manage the markers.
          const markerClusterer = new MarkerClusterer(map, markers);

          // Change styles after cluster is created
          const styles = markerClusterer.getStyles();
          for (let i = 0; i < styles.length; i++) {
            styles[i].textColor = 'white';
            styles[i].textSize = 14;
            styles[i].url = '/wp-content/themes/alvic/assets/img/markerclusther.svg';
          }

          break;

        default:
        // Aquí iría el caso real con datos de WordPress
      }

      ZoomControls(zoomControlslDiv, map);
      if (mapInfo[0] == 'multiple') {
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlslDiv);
      }
    });
  },
}
  // processMaps() {
  //   document.querySelectorAll('.map-container').forEach(mapContainer => {
  //     const mapInfo = mapContainer.dataset.mapinfo;
  //     const image = '/source/assets/img/marker.svg';
  //     const zoomControlslDiv = document.createElement("div");
  //     let map, markers;

  //     zoomControlslDiv.classList.add('zoom-control');
  //     zoomControlslDiv.style.display = "flex";

  //     function ZoomControls(zoomControlslDiv, map) {
  //       // Set CSS for the control border.
  //       const zoomout = document.createElement("div");

  //       zoomout.style.backgroundColor = "#000000";
  //       zoomout.style.cursor = "pointer";
  //       zoomout.style.textAlign = "center";
  //       zoomout.title = "REDUCIR";
  //       zoomControlslDiv.appendChild(zoomout);

  //       // Set CSS for the control interior.
  //       const zoomoutText = document.createElement("div");

  //       zoomoutText.style.color = "#ffffff";
  //       zoomoutText.style.fontFamily = "Euclid Circular A,Roboto,Arial,sans-serif";
  //       zoomoutText.style.fontSize = "12px";
  //       zoomoutText.style.lineHeight = "16,8px";
  //       zoomoutText.style.paddingLeft = "32px";
  //       zoomoutText.style.paddingRight = "12px";
  //       zoomoutText.style.paddingTop = "18.5px";
  //       zoomoutText.style.paddingBottom = "18.5px";
  //       zoomoutText.innerHTML = "REDUCIR";
  //       zoomout.appendChild(zoomoutText);
  //       // Setup the click event listeners
  //       zoomout.addEventListener("click", () => {
  //         map.setZoom(map.getZoom() - 1);
  //       });

  //       // Set CSS for the control border.
  //       const zoomin = document.createElement("div");

  //       zoomin.style.backgroundColor = "#000000";
  //       zoomin.style.cursor = "pointer";
  //       zoomin.style.textAlign = "center";
  //       zoomin.title = "AMPLIAR";
  //       zoomControlslDiv.appendChild(zoomin);

  //       // Set CSS for the control interior.
  //       const zoominText = document.createElement("div");

  //       zoominText.style.color = "#ffffff";
  //       zoominText.style.fontFamily = "Euclid Circular A,Roboto,Arial,sans-serif";
  //       zoominText.style.fontSize = "12px";
  //       zoominText.style.lineHeight = "16,8px";
  //       zoominText.style.paddingLeft = "12px";
  //       zoominText.style.paddingRight = "32px";
  //       zoominText.style.paddingTop = "18.5px";
  //       zoominText.style.paddingBottom = "18.5px";
  //       zoominText.innerHTML = "AMPLIAR";
  //       zoomin.appendChild(zoominText);
  //       // Setup the click event listeners
  //       zoomin.addEventListener("click", () => {
  //         map.setZoom(map.getZoom() + 1);
  //       });
  //     }

  //     switch (mapInfo) {
  //       case 'simple':
  //         const uluru = { lat: -25.344, lng: 131.036 };
  //         map = new google.maps.Map(mapContainer, {
  //           zoom: 4,
  //           center: uluru,
  //           mapId: '9a7c4f083fb64779',
  //           disableDefaultUI: true,
  //         });

  //         markers = [
  //           new google.maps.Marker({
  //             position: uluru,
  //             map: map,
  //             icon: image,
  //           })
  //         ];
  //         break;

  //       case 'multiple':
  //         map = new google.maps.Map(mapContainer, {
  //           zoom: 3,
  //           center: { lat: -28.024, lng: 140.887 },
  //           mapId: '9a7c4f083fb64779',
  //           disableDefaultUI: true,
  //         });

  //         const labels = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  //         const locations = [
  //           { lat: -31.56391, lng: 147.154312 },
  //           { lat: -33.718234, lng: 150.363181 },
  //           { lat: -33.727111, lng: 150.371124 },
  //           { lat: -33.848588, lng: 151.209834 },
  //           { lat: -33.851702, lng: 151.216968 },
  //           { lat: -34.671264, lng: 150.863657 },
  //           { lat: -35.304724, lng: 148.662905 },
  //           { lat: -36.817685, lng: 175.699196 },
  //           { lat: -36.828611, lng: 175.790222 },
  //           { lat: -37.75, lng: 145.116667 },
  //           { lat: -37.759859, lng: 145.128708 },
  //           { lat: -37.765015, lng: 145.133858 },
  //           { lat: -37.770104, lng: 145.143299 },
  //           { lat: -37.7737, lng: 145.145187 },
  //           { lat: -37.774785, lng: 145.137978 },
  //           { lat: -37.819616, lng: 144.968119 },
  //           { lat: -38.330766, lng: 144.695692 },
  //           { lat: -39.927193, lng: 175.053218 },
  //           { lat: -41.330162, lng: 174.865694 },
  //           { lat: -42.734358, lng: 147.439506 },
  //           { lat: -42.734358, lng: 147.501315 },
  //           { lat: -42.735258, lng: 147.438 },
  //           { lat: -43.999792, lng: 170.463352 },
  //         ];
  //         markers = locations.map((location, i) => {
  //           const marker = new google.maps.Marker({
  //             position: location,
  //             label: labels[i % labels.length],
  //             icon: image,
  //           });

  //           google.maps.event.addListener(marker, 'click', function(evt) {
  //              const card = document.querySelector('div.locationdata');
  //              card.querySelector('.type').innerText = element.location_type;
  //              card.querySelector('.name').innerText = element.location_name;
  //              card.querySelector('.address').innerText = element.location_address;
  //              card.querySelector('.phone').innerText = `Tel. +34 ${element.location_phone}`;
  //            });
  //           return marker;
  //         });

  //         // Add a marker clusterer to manage the markers.
  //         const markerClusterer = new MarkerClusterer(map, markers,);

  //         // Change styles after cluster is created
  //         const styles = markerClusterer.getStyles();
  //         for (let i = 0; i < styles.length; i++) {
  //           styles[i].textColor = 'white';
  //           styles[i].textSize = 14;
  //           styles[i].url = '/source/assets/img/markerclusther.svg';
  //         }

  //         break;

  //       default:
  //       // Aquí iría el caso real con datos de WordPress
  //     }

  //     ZoomControls(zoomControlslDiv, map);
  //     if (mapInfo=='multiple') {
  //       map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(zoomControlslDiv);
  //     }
  //   });
  // },
