import watchForHover from 'lib/input-detector';

import config from 'config';
import dev from 'dev';
import layout from 'layout';
import form from 'form';
import maps from 'maps';

//import modules  from 'modules';
window.addEventListener('DOMContentLoaded', () => {
  watchForHover();

  layout.init();
  form.init();

  maps.init();

  // Scroll event header
  window.addEventListener('scroll', () => {
    document.documentElement.classList.toggle('isScrolledToTop', window.scrollY == 0);
  });
  document.documentElement.classList.toggle('isScrolledToTop', window.scrollY == 0);

  // Main menu items events (hover or click listeners according to the resolution)
  if (window.innerWidth > 1250) {
    document.querySelectorAll('#header nav > ul > li').forEach(li => li.addEventListener('mouseenter', () => {
      const ul = li.parentElement;
      [...ul.children].forEach(li => li.classList.remove('expanded'));
      [...ul.children].forEach(li => li.classList.add('area-plus'));
      li.classList.add('expanded');
      document.querySelector('#header').classList.add("header--dark");
    }));
    document.querySelectorAll('#header nav > ul > li').forEach(li => li.addEventListener('mouseleave', () => {
      const ul = li.parentElement;
      [...ul.children].forEach(li => li.classList.remove('expanded', 'area-plus'));
      document.querySelector('#header').classList.remove("header--dark");
    }));

  } else {
    document.querySelectorAll('#header nav > ul.main-menu > li').forEach(li => li.addEventListener('click', event => {
      const ul = li.parentElement;
      let cont = li.classList.contains("expanded");
      [...ul.children].forEach(li => li.classList.remove('expanded'));
      let isExpanded = li.matches('.expanded');
      if (cont == false) {
        li.classList.toggle('expanded', !isExpanded);
      }
      document.querySelector('#header').classList.add("header--dark");
    }));

  }

  // Menu toggle event
  document.querySelector('.menu-toggle').addEventListener('click', () => {
    document.querySelector('.main-menu').classList.toggle("expanded");
    document.querySelector('#header').classList.toggle("header--dark");
    document.querySelector('.menu-toggle').classList.toggle("menu-toggle--expanded");
    document.querySelector('.menu-bg-layer').classList.toggle("visible");
    let toggle = document.querySelector('.menu-toggle span');
    if (toggle.innerHTML == "menu") {
      toggle.innerHTML = "cerrar";
    } else {
      toggle.innerHTML = "menu";
    }
  });

  // Patterns slider
  var patternSwiper = new window.Swiper('.swiper-container-patterns', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    slidesPerGroup: 3,
    loop: true,
    speed: 400,
    pagination: {
      el: '.swiper-pagination-patterns',
      clickable: true
    }, navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    }, breakpoints: {
      360: {
        pagination: false,
        slidesPerGroup: 2,
        slidesPerView: 2,
      },
      768: {
        slidesPerGroup: 3,
        slidesPerView: 3,
        pagination: {
          el: '.swiper-pagination-patterns',
          clickable: true
        }
      }, 992: {
        slidesPerGroup: 4,
        slidesPerView: 4,
        pagination: {
          el: '.swiper-pagination-patterns',
          clickable: true
        }
      }
    }
  });
  if (patternSwiper.slides.length > 2) {
    patternSwiper.navigation = false;
  }

  // Collections slider
  new window.Swiper('.swiper-container-collections', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    slidesPerGroup: 2,
    loopFillGroupWithBlank: true,
    loop: true,
    speed: 400,
    pagination: {
      el: '.swiper-pagination-collections',
      clickable: true
    }, navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      360: {
        slidesPerView: 1,
        slidesPerGroup: 1,
        pagination: false,
      },
      768: {
        slidesPerGroup: 2,
        slidesPerView: 2,
        pagination: {
          el: '.swiper-pagination-collections',
          clickable: true
        }
      },
    }
  });

  // Carrousel slider
  new window.Swiper('.swiper-container-carousel', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    loop: false,
    speed: 400,
    autoplay: {
      delay: 5000,
    },
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    pagination: {
      el: '.swiper-pagination-carousel',
      clickable: true
    }
  });


  // Pattern filters panel 
  document.querySelectorAll(".pattern-filter-btn").forEach(function (element) {
    element.addEventListener('click', function () {
      disableScrolling();
      document.querySelector('.pattern-filter-panel').classList.add("expanded");
    });
  });

  document.querySelectorAll(".pattern-panel-header .btn-close").forEach(function (element) {
    element.addEventListener('click', function () {
      document.querySelector('.pattern-filter-panel').classList.remove("expanded");
      enableScrolling();
    });
  });

  document.querySelectorAll(".pattern-filter-overlay").forEach(function (element) {
    element.addEventListener('click', function () {
      element.closest(".pattern-filter-wrap").querySelector('.pattern-filter-panel.expanded').classList.remove("expanded");
      event.stopPropagation();
      enableScrolling();
    });
  });

  document.querySelectorAll(".pattern-panel-list li").forEach(function (element) {
    element.addEventListener('click', function () {
      element.classList.toggle("selected");
      let countFilters = document.querySelectorAll(".pattern-panel-list li.selected").length;
      let numberFilterTxt = document.querySelector(".number-filters");
      let btnCleanFilter = document.querySelector(".btn-clean-filters");
      let panelHeader = document.querySelector(".pattern-panel-header");

      numberFilterTxt.textContent = countFilters.toString();
      btnCleanFilter.classList.remove("hidden");
      document.querySelector(".pattern-btn-results").classList.remove("hidden");
      panelHeader.classList.add("active");

      if (numberFilterTxt.textContent == "0") {
        btnCleanFilter.classList.add("hidden");
        document.querySelector(".pattern-btn-results").classList.add("hidden");
        panelHeader.classList.remove("active");
      }

    });
  });

  document.querySelectorAll(".btn-clean-filters").forEach(function (element) {
    element.addEventListener('click', function () {
      element.classList.add("hidden");
      document.querySelector(".pattern-btn-results").classList.add("hidden");
      document.querySelector(".pattern-panel-header").classList.remove("active");

      document.querySelectorAll(".pattern-panel-list li").forEach(function (element) {
        element.classList.remove("selected");
      });

    });
  });


  // Pattern focus effect
  var patternTimer;
  document.querySelectorAll(".pattern-card").forEach(function (element) {
    element.addEventListener('mouseenter', function () {
      let allPatterns = element.parentElement.parentElement.querySelectorAll("a");
      let anchorPattern = element.parentElement;
      patternTimer = setTimeout(function () {
        for (var item of allPatterns) {
          item.querySelector(".pattern-card").classList.add('not-focus');
        }
        element.classList.remove('not-focus');
      }, 1000);
    });

    element.addEventListener('mouseleave', function () {
      let allPatterns = element.parentElement.parentElement.querySelectorAll("a");
      clearTimeout(patternTimer);
      for (var item of allPatterns) {
        item.querySelector(".pattern-card").classList.remove('not-focus');
      }
    });
  });


  // Collections focus effect
  var collectionTimer;
  document.querySelectorAll(".collection-card").forEach(function (element) {
    element.addEventListener('mouseenter', function () {
      let allCollection = element.parentElement.parentElement.querySelectorAll("a");
      let anchorPattern = element.parentElement;
      collectionTimer = setTimeout(function () {
        for (var item of allCollection) {
          item.querySelector(".collection-card").classList.add('not-focus');
        }
        element.classList.remove('not-focus');
      }, 1000);
    });

    element.addEventListener('mouseleave', function () {
      let allCollection = element.parentElement.parentElement.querySelectorAll("a");
      clearTimeout(collectionTimer);
      for (var item of allCollection) {
        item.querySelector(".collection-card").classList.remove('not-focus');
      }
    });
  });


  // Video lightbox (image full module)
  var lightbox = GLightbox();
  var lightboxVideo = GLightbox({
    selector: '.video-lightbox-image-full',
  });


  // Image gallery lightbox
  var lightboxImage = GLightbox({
    selector: '.gallery-lightbox',
  });


  // Image gallery panel 
  document.querySelectorAll(".gallery-item-layer").forEach(function (element) {
    element.addEventListener('click', function () {
      disableScrolling();
      for (var item of document.querySelectorAll('.gallery-item-layer')) {
        item.classList.remove('expanded');
      }
      element.classList.add("expanded");
    });
  });

  document.querySelectorAll(".close-layer").forEach(function (element) {
    element.addEventListener('click', function () {
      element.closest(".gallery-item-layer").classList.remove("expanded");
      event.stopPropagation();
      enableScrolling();
    });
  });

  document.querySelectorAll(".gallery-overlay").forEach(function (element) {
    element.addEventListener('click', function () {
      element.closest(".gallery-wrap").querySelector('.gallery-item-layer.expanded').classList.remove("expanded");
      event.stopPropagation();
      enableScrolling();
    });
  });


  // Worldwide presence tabs  
  document.querySelectorAll(".ubication-tabs .tabs a").forEach(function (element, index) {
    element.addEventListener('click', function () {
      for (var item of document.querySelectorAll('.contact-list')) {
        item.classList.remove('active');
      }
      for (var item of document.querySelectorAll('.ubication-tabs .tabs a')) {
        item.classList.remove('active');
      }
      element.classList.add("active");
      document.querySelectorAll('.contact-list')[index].classList.add("active");
    });
  });


  // Forms tabs  
  document.querySelectorAll(".module--form .tabs a").forEach(function (element, index) {
    element.addEventListener('click', function () {
      for (var item of document.querySelectorAll('.form-wra')) {
        item.classList.remove('active');
      }
      for (var item of document.querySelectorAll('.module--form .tabs a')) {
        item.classList.remove('active');
      }
      element.classList.add("active");
      document.querySelectorAll('.form-wrap')[index].classList.add("active");
    });
  });

  document.querySelectorAll(".heading-wrap .tabs a").forEach(function (element, index) {
    element.addEventListener('click', function () {
      for (var item of document.querySelectorAll('.heading-wrap .tabs a')) {
        item.classList.remove('active');
      }
      for (var item of document.querySelectorAll('.ubication-tab')) {
        item.classList.remove('active');
      }
      element.classList.toggle('active');
      document.querySelectorAll('.ubication-tab')[index].classList.add("active");
    });
  });

  // Forms tabs  
  document.querySelectorAll(".module--form .tabs a").forEach(function (element, index) {
    element.addEventListener('click', function () {
      for (var item of document.querySelectorAll('.form-wrap')) {
        item.classList.remove('active');
      }
      for (var item of document.querySelectorAll('.module--form .tabs a')) {
        item.classList.remove('active');
      }
      element.classList.add("active");
      document.querySelectorAll('.form-wrap')[index].classList.add("active");
    });
  });


  //****************  SLIDERS   ****************//

  var projectTimerSlider = [];
  var textImageTimerSlider = [];
  var heroTimerSlider = [];

  //move circle indicator control slider
  function sliderControls(allBullets, activeBullet, index) {
    let circleIndicator = activeBullet.closest(".slider-pagination").querySelector("svg");
    let svgCircleGrow = circleIndicator.querySelector(".circle-grow");
    let positionCircle = index * 43;
    for (let bullet of allBullets) {
      bullet.classList.remove("slider-pagination-bullet-active");
    }
    activeBullet.classList.add("slider-pagination-bullet-active");
    circleIndicator.style.transform = `translate(${positionCircle}px,0) rotate(-93deg)`;
    setTimeout(function () { svgCircleGrow.classList.remove('animate') }, 5000);
    svgCircleGrow.classList.add('animate');
  }

  //stop slider auto
  function stopSlider(timerSlider, svgCircle) {
    clearInterval(timerSlider);
    svgCircle.setAttribute("style", "animation: none; stroke: transparent");
  }


  //timer project slider
  function timerProjectSlider(index) {

    projectTimerSlider[index] = setInterval(function () {
      let allActiveBullets = document.querySelectorAll(".left-right--projects-slider .slider-pagination-bullet-active");
      for (let activeBullet of allActiveBullets) {
        let allBullets = activeBullet.closest(".slider-pagination").querySelectorAll(".slider-pagination-bullet");

        if (activeBullet.nextElementSibling) {
          projectSlider(activeBullet.nextElementSibling);
        } else {
          projectSlider(allBullets[0]);
        }
      }
    }, 6000);

  }

  function projectSlider(element) {
    let allBullets = element.closest(".left-right--projects-slider").querySelectorAll(".slider-pagination-bullet");
    let index = (Array.from(allBullets).indexOf(element));
    let allSlideText = element.closest(".left-right--projects-slider").querySelectorAll(".slide-part-text");
    let allSlideImg = element.closest(".left-right--projects-slider").querySelectorAll(".slide-part-img");
    let allSlideCard = element.closest(".left-right--projects-slider").querySelectorAll(".slide-part-card");

    for (let i = 0; i < allSlideText.length; i++) {
      allSlideText[i].classList.remove("active");
      allSlideImg[i].classList.remove("active");
      allSlideCard[i].classList.remove("active");
    }
    allSlideText[index].classList.add("active");
    allSlideImg[index].classList.add("active");
    allSlideCard[index].classList.add("active");

    sliderControls(allBullets, element, index);
  }

  //init project slider auto
  document.querySelectorAll(".left-right--projects-slider .slider-pagination").forEach((slider, index) => {
    slider._index = index;
    let svgCircleGrow = slider.querySelector(".circle-grow");
    setTimeout(function () { svgCircleGrow.classList.remove('animate') }, 6000);
    timerProjectSlider(index);
  });

  // Click event Project slider left-right module
  document.querySelectorAll(".left-right--projects-slider .slider-pagination-bullet").forEach(function (element) {
    element.addEventListener('click', function () {
      let sliderPagination = element.closest(".slider-pagination");
      let svgCircle = element.closest(".slider-pagination").querySelector(".circle-grow");
      let indexSlider = sliderPagination._index;
      stopSlider(projectTimerSlider[indexSlider], svgCircle);
      projectSlider(element);
    });
  });


  // two-images slider left-right module


  //timer text image slider
  function timerTextImageSlider(index) {

    textImageTimerSlider[index] = setInterval(function () {
      let allActiveBullets = document.querySelectorAll(".left-right--two-images-slider .slider-pagination-bullet-active");
      let activeBullet = allActiveBullets[index];
      let allBullets = activeBullet.closest(".slider-pagination").querySelectorAll(".slider-pagination-bullet");
      if (activeBullet.nextElementSibling) {
        textImageSlider(activeBullet.nextElementSibling);
      } else {
        textImageSlider(allBullets[0]);
      }
    }, 6000);
  }

  function textImageSlider(element) {
    let slider = element.closest(".left-right--two-images-slider");
    let allBullets = slider.querySelectorAll(".slider-pagination-bullet");
    let index = (Array.from(allBullets).indexOf(element));
    let allSlideText = slider.querySelectorAll(".slide-part-text");
    let allSlideImg1 = slider.querySelectorAll(".slide-part-img1");
    let allSlideImg2 = slider.querySelectorAll(".slide-part-img2");

    for (let i = 0; i < allSlideText.length; i++) {
      allSlideText[i].classList.remove("active");
      allSlideImg1[i].classList.remove("active");
      allSlideImg2[i].classList.remove("active");
    }
    allSlideText[index].classList.add("active");
    allSlideImg1[index].classList.add("active");
    allSlideImg2[index].classList.add("active");

    sliderControls(allBullets, element, index);
  }

  //init text image slider auto
  document.querySelectorAll(".left-right--two-images-slider .slider-pagination").forEach((slider, index) => {
    slider._index = index;
    let svgCircleGrow = slider.querySelector(".circle-grow");
    setTimeout(function () { svgCircleGrow.classList.remove('animate') }, 5000);
    timerTextImageSlider(index);
  });

  // Click event text image slider left-right module
  document.querySelectorAll(".left-right--two-images-slider .slider-pagination-bullet").forEach(function (element) {
    element.addEventListener('click', function () {
      let sliderPagination = element.closest(".slider-pagination");
      let svgCircle = sliderPagination.querySelector(".circle-grow");
      let indexSlider = sliderPagination._index;
      stopSlider(textImageTimerSlider[indexSlider], svgCircle);
      textImageSlider(element);
    });
  });


  //timer hero slider
  function timerHeroSlider(index) {
    heroTimerSlider[index] = setInterval(function () {
      let allActiveBullets = document.querySelectorAll(".module--hero.hero--home .slider-pagination-bullet-active");
      let activeBullet = allActiveBullets[index];
      let allBullets = activeBullet.closest(".slider-pagination").querySelectorAll(".slider-pagination-bullet");
      if (activeBullet.nextElementSibling) {
        heroSlider(activeBullet.nextElementSibling);
      } else {
        heroSlider(allBullets[0]);
      }
    }, 6000);
  }

  function heroSlider(element) {
    let slider = element.closest(".hero--home");
    let allBullets = slider.querySelectorAll(".slider-pagination-bullet");
    let index = (Array.from(allBullets).indexOf(element));
    let slides = slider.querySelectorAll(".slide");

    for (let i = 0; i < slides.length; i++) {
      slides[i].classList.remove("active");
    }
    slides[index].classList.add("active");

    sliderControls(allBullets, element, index);
  }

  //init hero slider auto
  document.querySelectorAll(".module--hero.hero--home .slider-pagination").forEach((slider, index) => {
    slider._index = index;
    let svgCircleGrow = slider.querySelector(".circle-grow");
    let slides = slider.closest(".module").querySelectorAll(".slide");
    slides[0].classList.add("active");
    setTimeout(function () { svgCircleGrow.classList.remove('animate') }, 5000);
    timerHeroSlider(index);
  });

  // Click event hero slider module
  document.querySelectorAll(".module--hero.hero--home .slider-pagination-bullet").forEach(function (element) {
    element.addEventListener('click', function () {
      let sliderPagination = element.closest(".slider-pagination");
      let svgCircle = sliderPagination.querySelector(".circle-grow");
      let indexSlider = sliderPagination._index;
      stopSlider(heroTimerSlider[indexSlider], svgCircle);
      heroSlider(element);
    });
  });


  //****************  END SLIDERS   ****************//


  // Animation social share
  document.querySelectorAll(".social-wrap li").forEach(function (element) {
    let socialShare = element.closest('.social-share');

    element.addEventListener('mouseenter', function () {
      let allSocialItems = element.closest('ul').querySelectorAll("li");
      let index = (Array.from(allSocialItems).indexOf(element));
      let colorSelected;
      switch (index) {
        case 0:
          colorSelected = "facebook";
          break;
        case 1:
          colorSelected = "instagram";
          break;
        case 2:
          colorSelected = "twitter";
          break;
        case 3:
          colorSelected = "mail";
          break;
      }
      socialShare.classList.add(colorSelected);

    });
    element.addEventListener('mouseleave', function () {
      socialShare.classList.remove("facebook", "instagram", "twitter", "mail");
    });
  });

  // Animation play button
  document.querySelectorAll(".play-btn-wrap svg").forEach(function (element) {
    let playWrap = element.closest(".play-btn-wrap");
    element.addEventListener('mouseenter', function () {
      playWrap.classList.remove("not-focus");
      playWrap.classList.add("focus");
    });
    element.addEventListener('mouseleave', function () {
      playWrap.classList.remove("focus");
      playWrap.classList.add("not-focus");
    });
  });

  // Disable/enable scroll
  function disableScrolling() {
    var x = window.scrollX;
    var y = window.scrollY;
    window.onscroll = function () { window.scrollTo(x, y); };
  }
  function enableScrolling() {
    window.onscroll = function () { };
  }
  window.addEventListener('scroll', () => {
    if (!window.disableParallax) {
      requestAnimationFrame(updateParallax);
    }
  });
  if (config.dev) {
    dev.init();
  }
});


// background lines 
function calcHeightBgLines() {
  document.querySelector(".bg-line").style.height = `${document.body.clientHeight}px`;
}
window.addEventListener("resize", calcHeightBgLines);

// Parallax effect
function updateParallax() {
  document.querySelectorAll('[data-parallax]').forEach(node => {
    const scrollY = window.scrollY,
      screenHeight = window.innerHeight,
      [transformX, transformY] = node.dataset.parallax.split(','),
      boundingRect = node.getBoundingClientRect(),
      scrollMin = scrollY + boundingRect.top - screenHeight,
      scrollMax = scrollY + boundingRect.bottom,
      t = -.5 + (scrollY - scrollMin) / (scrollMax - scrollMin);

    if (t >= -.5 && t <= .5) {
      node.style.transform = `translate3d(${t * transformX}%,${t * transformY}%,0)`;
    }
  });
}

// Scroll Fade animations
document.querySelectorAll(".fade-up").forEach(function (element) {
  element.setAttribute("data-aos", "custom-fade-up");
});
document.querySelectorAll(".fade").forEach(function (element) {
  element.setAttribute("data-aos", "custom-fade");
});

setTimeout(() => {
  AOS.init({
    duration: 1000, // values from 0 to 3000, with step 50ms
    easing: 'ease',
    once: true,
    anchorPlacement: 'center-center',
  });
}, 5);