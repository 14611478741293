export default {
  init() {
    document.querySelectorAll('form.form').forEach(form => form.addEventListener('submit', event => {
      const action  = form.getAttribute('action'),
            isMock  = /assets\/json/.test(action),
            options = {};

      form.classList.add('form--busy');

      if(isMock) {
        options.method = 'get';
      } else {
        options.method = 'post';
        options.body = new FormData(form);
      }

      setTimeout(() => {
        fetch(action, options)
          .then(response => response.json())
          .then(response => {
            form.classList.remove('form--busy');
            if(response.action) {
              form.setAttribute('action', response.action);
            }
            if(response.status == 'ok') {
              form.parentElement.classList.add('hidden');
              document.querySelectorAll('.form-success')[0].classList.add("visible");
            } else {
              let hasErrors = false;
              response.fields.forEach(name => {
                const input = form.querySelector(`[name="${name}"]`);
                input && input.closest('.field').classList.add('has-error');
                hasErrors = true;
              });
              form.querySelector('.row--error').classList.toggle('expanded', hasErrors);
            }
          })
          .catch(() => {
            form.classList.remove('form--busy');
          })
      }, isMock ? 1500 : 0);
      event.preventDefault();
    }));

    document.querySelectorAll('.select-wrap select').forEach(select => {
      select.addEventListener('change', () => {
        const selectedOption = select.querySelector(':checked'),
              placeholder = select.closest('.select-wrap').querySelector('.placeholder');
        placeholder.innerText = selectedOption.innerText;
        placeholder.classList.toggle('empty', !selectedOption.value);
      });
    });

    document.querySelectorAll('form.form input, form.form textarea, form.form select').forEach(input => {
      input.addEventListener('focus', () => {
        const fieldWithError = input.closest('.has-error');
        if(fieldWithError) {
          fieldWithError.classList.remove('has-error');

          // Nested errors?
          const nestedError = fieldWithError.closest('.has-error');
          if(nestedError) {
            nestedError.classList.remove('has-error');
          }
        }
        const remainingErrors = input.closest('.form').querySelectorAll('.field.has-error').length;
        input.closest('.form').querySelectorAll('.row--error').forEach(rowError => rowError.classList.toggle('expanded', remainingErrors > 0));
      });
    });
    
    document.querySelectorAll('form.form input:not([type="checkbox"]), form.form textarea, form.form select').forEach(input => {
      const fieldclass = input.closest('.field')

      input.addEventListener('focus', () => {
        fieldclass.classList.add('active');
      });

      input.addEventListener('change', () => {
        fieldclass.classList.add('active');
      });
  
      input.addEventListener('blur', () => {
        if(input.value.length == 0) {
          fieldclass.classList.remove('active');
        }
      });

    });
  }
}