import utils from 'utils';

export default {

  init() {
    this.attachResizeHandler();
  },

  //

  attachResizeHandler() {
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
    window.dispatchEvent(new Event('resize'));
  },

  onResize() {
    const halfGutterWidth   = parseInt(utils.getCssVar('half-gutter')),
          containerWide     = document.querySelector('.container--wide'),
          containerRegular  = document.querySelector('.container:not(.container--wide)'),
          wideWidth         = containerWide.getBoundingClientRect().width,
          regularWidth      = containerRegular.getBoundingClientRect().width - 2 * halfGutterWidth,
          diff              = (wideWidth - regularWidth) / 2,
          colWidth          = document.querySelector('.col-1 .inner').clientWidth;
    document.documentElement.style.setProperty('--grid-diff', `${diff}px`);
    utils.setCssVar('col-width', colWidth + 'px');
  }

}